<template>
  <div
    v-if="isAdvanced"
    class="p-fileupload p-fileupload-advanced p-component"
  >
    <div class="flex">
      <base-button
        v-ripple
        :class="advancedChooseButtonClass"
        size="button--sm"
        class="mr-1"
        tabindex="0"
        @click="choose"
        @keydown.enter="choose"
        @focus="onFocus"
        @blur="onBlur"
      >
        <input
          ref="fileInput"
          type="file"
          :multiple="multiple"
          :accept="accept"
          :disabled="chooseDisabled"
          @change="onFileSelect"
        >
        <i class="ri-add-line mr-2" />
        <span class="p-button-label">{{ chooseLabel }}</span>
      </base-button>
      <FileUploadButton
        class="mr-1"
        size="button--sm"
        :label="uploadLabel"
        icon="ri-upload-line mr-2"
        :disabled="uploadDisabled"
        @click="upload"
      />
      <FileUploadButton
        :label="cancelLabel"
        class="button--sm"
        icon="ri-close-line mr-2"
        :disabled="cancelDisabled"
        @click="clear"
      />
    </div>
    <div
      ref="content"
      class="p-fileupload-content"
      @dragenter="onDragEnter"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <FileUploadProgressBar
        v-if="hasFiles"
        :value="progress"
      />
      <FileUploadMessage
        v-for="msg of messages"
        :key="msg"
        severity="error"
      >
        {{ msg }}
      </FileUploadMessage>
      <div
        v-if="hasFiles"
        class="p-fileupload-files mt-2 p-2 border-2 rounded-md"
      >
        <div
          v-for="(file, index) of files"
          :key="file.name + file.type + file.size"
          class="w-full flex items-center justify-between border-b-2"
        >
          <div class="flex items-center space-x-2">
            <div
              v-if="isImage(file)"
              class="flex-none w-8 h-8 relative"
            >
              <img
                role="presentation"
                :alt="file.name"
                :src="file.objectURL"
                class="absolute inset-0 w-full h-full object-cover"
              >
            </div>
            <div>{{ file.name }}</div>
          </div>
          <div class="flex items-center">
            <div>{{ formatSize(file.size) }}</div>
            <div class="ml-2">
              <a
                class="text-red-500 hover:text-red-700 cursor-pointer"
                @click.prevent="remove(index)"
              >
                <i class="ri-delete-bin-fill" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$slots.empty && !hasFiles"
      class="p-fileupload-empty"
    >
      <slot name="empty" />
    </div>
  </div>
  <div
    v-else-if="isBasic"
    class="p-fileupload p-fileupload-basic p-component"
  >
    <FileUploadMessage
      v-for="msg of messages"
      :key="msg"
      severity="error"
    >
      {{ msg }}
    </FileUploadMessage>
    <span
      v-ripple
      :class="basicChooseButtonClass"
      tabindex="0"
      @mouseup="onBasicUploaderClick"
      @keydown.enter="choose"
      @focus="onFocus"
      @blur="onBlur"
    >
      <span :class="basicChooseButtonIconClass" />
      <span class="p-button-label">{{ basicChooseButtonLabel }}</span>
      <input
        v-if="!hasFiles"
        ref="fileInput"
        type="file"
        :accept="accept"
        :disabled="disabled"
        @change="onFileSelect"
        @focus="onFocus"
        @blur="onBlur"
      >
    </span>
  </div>
</template>

<script>
import Button from "../button/Button";
import ProgressBar from "../progressbar/ProgressBar";
import Message from "../message/Message";
import DomHandler from "../utils/DomHandler";
import Ripple from "../ripple/Ripple";

export default {
  components: {
    FileUploadButton: Button,
    FileUploadProgressBar: ProgressBar,
    FileUploadMessage: Message
  },
  directives: {
    ripple: Ripple
  },
  props: {
    name: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: "advanced"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    maxFileSize: {
      type: Number,
      default: null
    },
    invalidFileSizeMessage: {
      type: String,
      default: "{0}: Invalid file size, file size should be smaller than {1}."
    },
    fileLimit: {
      type: Number,
      default: null
    },
    invalidFileLimitMessage: {
      type: String,
      default: "Maximum number of files exceeded, limit is {0} at most."
    },
    withCredentials: {
      type: Boolean,
      default: false
    },
    previewWidth: {
      type: Number,
      default: 30
    },
    chooseLabel: {
      type: String,
      default: "Choose"
    },
    uploadLabel: {
      type: String,
      default: "Upload"
    },
    cancelLabel: {
      type: String,
      default: "Cancel"
    },
    customUpload: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "select",
    "uploader",
    "before-upload",
    "progress",
    "upload",
    "error",
    "before-send",
    "clear"
  ],
  duplicateIEEvent: false,
  uploadedFileCount: 0,
  data() {
    return {
      files: null,
      messages: null,
      focused: false,
      progress: null
    };
  },
  computed: {
    isAdvanced() {
      return this.mode === "advanced";
    },
    isBasic() {
      return this.mode === "basic";
    },
    advancedChooseButtonClass() {
      return [
        "p-button p-component p-fileupload-choose",
        {
          "p-disabled": this.disabled,
          "p-focus": this.focused
        }
      ];
    },
    basicChooseButtonClass() {
      return [
        "p-button p-component p-fileupload-choose",
        {
          "p-fileupload-choose-selected": this.hasFiles,
          "p-disabled": this.disabled,
          "p-focus": this.focused
        }
      ];
    },
    basicChooseButtonIconClass() {
      return [
        "p-button-icon p-button-icon-left pi",
        {
          "pi-plus": !this.hasFiles || this.auto,
          "pi-upload": this.hasFiles && !this.auto
        }
      ];
    },
    basicChooseButtonLabel() {
      return this.auto
        ? this.chooseLabel
        : this.hasFiles
        ? this.files[0].name
        : this.chooseLabel;
    },
    hasFiles() {
      return this.files && this.files.length > 0;
    },
    chooseDisabled() {
      return (
        this.disabled ||
        (this.fileLimit &&
          this.fileLimit <= this.files.length + this.uploadedFileCount)
      );
    },
    uploadDisabled() {
      return this.disabled || !this.hasFiles;
    },
    cancelDisabled() {
      return this.disabled || !this.hasFiles;
    }
  },
  methods: {
    onFileSelect(event) {
      if (event.type !== "drop" && this.isIE11() && this.duplicateIEEvent) {
        this.duplicateIEEvent = false;
        return;
      }

      this.messages = [];
      this.files = this.files || [];
      let files = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;
      for (let file of files) {
        if (!this.isFileSelected(file)) {
          if (this.validate(file)) {
            if (this.isImage(file)) {
              file.objectURL = window.URL.createObjectURL(file);
            }
            this.files.push(file);
          }
        }
      }

      this.$emit("select", { originalEvent: event, files: files });

      if (this.fileLimit) {
        this.checkFileLimit();
      }

      if (this.auto && this.hasFiles && !this.isFileLimitExceeded()) {
        this.upload();
      }

      if (event.type !== "drop" && this.isIE11()) {
        this.clearIEInput();
      } else {
        this.clearInputElement();
      }
    },
    choose() {
      this.$refs.fileInput.click();
    },
    upload() {
      if (this.customUpload) {
        if (this.fileLimit) {
          this.uploadedFileCount += this.files.length;
        }

        this.$emit("uploader", { files: this.files });
      } else {
        let xhr = new XMLHttpRequest();
        let formData = new FormData();

        this.$emit("before-upload", {
          xhr: xhr,
          formData: formData
        });

        for (let file of this.files) {
          formData.append(this.name, file, file.name);
        }

        xhr.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
            this.progress = Math.round((event.loaded * 100) / event.total);
          }

          this.$emit("progress", {
            originalEvent: event,
            progress: this.progress
          });
        });

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            this.progress = 0;

            if (xhr.status >= 200 && xhr.status < 300) {
              if (this.fileLimit) {
                this.uploadedFileCount += this.files.length;
              }

              this.$emit("upload", {
                xhr: xhr,
                files: this.files
              });
            } else {
              this.$emit("error", {
                xhr: xhr,
                files: this.files
              });
            }

            this.clear();
          }
        };

        xhr.open("POST", this.url, true);

        this.$emit("before-send", {
          xhr: xhr,
          formData: formData
        });

        xhr.withCredentials = this.withCredentials;

        xhr.send(formData);
      }
    },
    clear() {
      this.files = null;
      this.messages = null;
      this.$emit("clear");

      if (this.isAdvanced) {
        this.clearInputElement();
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    isFileSelected(file) {
      if (this.files && this.files.length) {
        for (let sFile of this.files) {
          if (
            sFile.name + sFile.type + sFile.size ===
            file.name + file.type + file.size
          )
            return true;
        }
      }

      return false;
    },
    isIE11() {
      return !!window["MSInputMethodContext"] && !!document["documentMode"];
    },
    validate(file) {
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.messages.push(
          this.invalidFileSizeMessage
            .replace("{0}", file.name)
            .replace("{1}", this.formatSize(this.maxFileSize))
        );
        return false;
      }

      return true;
    },
    onDragEnter(event) {
      if (!this.disabled) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragOver() {
      if (!this.disabled) {
        DomHandler.addClass(this.$refs.content, "p-fileupload-highlight");
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragLeave() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, "p-fileupload-highlight");
      }
    },
    onDrop() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, "p-fileupload-highlight");
        event.stopPropagation();
        event.preventDefault();

        const files = event.dataTransfer
          ? event.dataTransfer.files
          : event.target.files;
        const allowDrop = this.multiple || (files && files.length === 1);

        if (allowDrop) {
          this.onFileSelect(event);
        }
      }
    },
    onBasicUploaderClick() {
      if (this.hasFiles) this.upload();
      else this.$refs.fileInput.click();
    },
    remove(index) {
      this.clearInputElement();
      this.files.splice(index, 1);
      this.files = [...this.files];
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    clearInputElement() {
      this.$refs.fileInput.value = "";
    },
    clearIEInput() {
      if (this.$refs.fileInput) {
        this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
        this.$refs.fileInput.value = "";
      }
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    isFileLimitExceeded() {
      if (
        this.fileLimit &&
        this.fileLimit <= this.files.length + this.uploadedFileCount &&
        this.focused
      ) {
        this.focused = false;
      }

      return (
        this.fileLimit &&
        this.fileLimit < this.files.length + this.uploadedFileCount
      );
    },
    checkFileLimit() {
      if (this.isFileLimitExceeded()) {
        this.msgs.push({
          severity: "error",
          summary: this.invalidFileLimitMessageSummary.replace(
            "{0}",
            this.fileLimit.toString()
          ),
          detail: this.invalidFileLimitMessageDetail.replace(
            "{0}",
            this.fileLimit.toString()
          )
        });
      }
    }
  }
};
</script>

<style>
.p-fileupload-content {
  position: relative;
}

.p-fileupload-row {
  display: flex;
  align-items: center;
}

.p-fileupload-row > div {
  flex: 1 1 auto;
  width: 25%;
}

.p-fileupload-row > div:last-child {
  text-align: right;
}

.p-fileupload-content .p-progressbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.p-button.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}

.p-button.p-fileupload-choose input[type="file"] {
  display: none;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type="file"] {
  display: none;
}

.p-fluid .p-fileupload .p-button {
  width: auto;
}
</style>
