<script>
import { defineComponent } from "vue";

import EditorJS from "@editorjs/editorjs";

import AutoComplete from "@/components/autocomplete/AutoComplete";
import FileUpload from "@/components/fileupload/FileUpload";
import Calendar from "@/components/calendar/Calendar";

import { useErrors } from "@/compositions/useErrors";
import { useFiles } from "@/compositions/useFiles";
import { useProject } from "@/compositions/models/useProject";

import { mapActions, mapGetters } from "vuex";
import Header from "@editorjs/header";
import SimpleImage from "@editorjs/simple-image";
import List from "@editorjs/list";
import Checklist from "@editorjs/checklist";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";

export default defineComponent({
  name: "ProjectEdit",
  components: { AutoComplete, Calendar, FileUpload },
  setup() {
    const errors = useErrors();
    const files = useFiles();
    const project = useProject();

    errors.errorInitContainers(["attachments"]);

    return {
      ...errors,
      files,
      status_variants: project.status.variants
    };
  },
  data() {
    return {
      loading: false,
      errors: [],
      editor: null,
      types: [],
      project: {
        name: "",
        description: "",
        budget: null,
        estimated_amount: null,
        started_at: null,
        ended_at: null,
        skills: [],
        status: {},
        type: null,
        statuses: [],
        attachments: [],
      }
    };
  },
  computed: {
    ...mapGetters("skill", ["skillList"])
  },
  async created() {
    try {
      const { data } = await this.getTypes();
      this.types = data;
    } catch (e) {
      console.log(e);
    }
    this.getProject(this.$route.params.id).then(r => {
      this.project = r.data;
      this.editor = new EditorJS({
        holder: "editor",
        placeholder: "Let`s write an awesome story!",
        /**
         * Available Tools list.
         * Pass Tool's class or Settings object for each Tool you want to use
         */
        tools: {
          /**
           * Each Tool is a Plugin. Pass them via 'class' option with necessary settings {@link docs/tools.md}
           */
          header: {
            class: Header,
            inlineToolbar: ["link"],
            config: {
              placeholder: "Header"
            },
            shortcut: "CMD+SHIFT+H"
          },
          /**
           * Or pass class directly without any configuration
           */
          image: {
            class: SimpleImage,
            inlineToolbar: ["link"]
          },
          list: {
            class: List,
            inlineToolbar: true,
            shortcut: "CMD+SHIFT+L"
          },
          checklist: {
            class: Checklist,
            inlineToolbar: true
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            config: {
              quotePlaceholder: "Enter a quote",
              captionPlaceholder: "Quote's author"
            },
            shortcut: "CMD+SHIFT+O"
          },
          marker: {
            class: Marker,
            shortcut: "CMD+SHIFT+M"
          },
          delimiter: Delimiter,
          inlineCode: {
            class: InlineCode,
            shortcut: "CMD+SHIFT+C"
          },
          linkTool: LinkTool,
          embed: Embed,
          table: {
            class: Table,
            inlineToolbar: true,
            shortcut: "CMD+ALT+T"
          }
        },

        /**
         * Previously saved data that should be rendered
         */
        data: r.data.description
      });
    });
  },
  methods: {
    ...mapActions("project", [
      "getProject",
      "projectUpdate",
      "projectAttachmentUpload",
      "projectAttachmentDelete"
    ]),
    ...mapActions("projectType", ["getTypes"]),
    ...mapActions("skill", ["getSkillList"]),
    async uploadAttachments({ files }) {
      this.errorSet([], "attachments");
      try {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          formData.append(`attachments[${i}]`, files[i], files[i].name);
        }

        const { data } = await this.projectAttachmentUpload({
          id: this.project.id,
          attachments: formData
        });

        this.project.attachments = data;
        this.$refs.attachmentUploader.clear();
        this.errorSet([], "attachments");
      } catch (e) {
        this.errorSet(e.errors, "attachments");
      }
    },
    async attachmentDelete(mediaId) {
      try {
        await this.projectAttachmentDelete({ id: this.project.id, mediaId });
        const index = this.project.attachments.findIndex(a => a.id === mediaId);
        this.project.attachments.splice(index, 1);
      } catch (e) {
        console.log(e);
      }
    },
    searchSkill(event) {
      this.getSkillList(event.query);
    },
    async update() {
      this.loading = true;

      try {
        const outputData = await this.editor.save();

        try {
          let project = this.project;
          project.description = outputData;

          await this.projectUpdate(project);

          this.loading = false;
        } catch (e) {
          this.errors = e.errors;
          this.loading = false;
        }
      } catch (e) {
        console.log("Saving failed: ", e);
      }
    }
  }
});
</script>

<template>
  <div class="intro-y flex flex-row items-center justify-between mt-8">
    <h2 class="text-lg font-medium">
      {{ project.name }} | Edit
    </h2>
    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <router-link
        :to="{
          name: 'dashboard.client.project.show',
          params: { id: project.id }
        }"
        title="Preview"
        class="button box text-gray-700 dark:text-gray-300 mr-2 flex items-center ml-auto sm:ml-0"
      >
        <i class="ri-eye-fill text-blue-500" />
      </router-link>
      <base-button
        :loading="loading"
        class="button w-24"
        @click="update"
      >
        Update
      </base-button>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="intro-y col-span-12 lg:col-span-8">
      <!-- BEGIN: Form Layout -->
      <div class="intro-y box p-2 md:p-5">
        <div :class="{ 'has-error': errorHas('name') }">
          <label class="font-medium">Project name</label>
          <input
            v-model="project.name"
            type="text"
            class="input w-full border mt-2"
            placeholder="Project name"
          >
          <span
            v-if="errorHas('name')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("name") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('skills') }"
        >
          <label class="font-medium">Skills</label>
          <div class="relative mt-2">
            <AutoComplete
              v-model="project.skills"
              class="input"
              field="name"
              :suggestions="skillList"
              :multiple="true"
              @complete="searchSkill($event)"
            />
          </div>
          <span
            v-if="errorHas('skills')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("skills") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('description') }"
        >
          <label class="font-medium">Description</label>
          <div class="prose lg:prose-xl max-w-none mt-2">
            <div
              id="editor"
              class="bg-white rounded-lg"
            />
          </div>
          <span
            v-if="errorHas('description')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("description") }}
          </span>
        </div>
      </div>
      <!-- END: Form Layout -->
    </div>
    <div class="intro-y col-span-12 lg:col-span-4 space-y-5">
      <div class="">
        <div :class="{ 'has-error': errorHas('status') }">
          <label class="font-medium">Status</label>
          <div class="relative mt-2">
            <base-select
              v-model="project.status"
              :model-value="project.status"
              class="mb-8 w-xs"
              :options="project.statuses"
            >
              <template #optionSelected="props">
                <div class="flex items-center">
                  <div
                    class="w-4 h-4 rounded-full mr-1"
                    :class="status_variants[props.option.key]"
                  />
                  {{ props.option.value }}
                </div>
              </template>
              <template #option="props">
                <div class="flex items-center">
                  <div
                    class="w-4 h-4 rounded-full mr-1"
                    :class="status_variants[props.option.key]"
                  />
                  {{ props.option.value }}
                </div>
              </template>
            </base-select>
          </div>
          <span
            v-if="errorHas('status')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("status") }}
          </span>
        </div>
      </div>
      <div class="">
        <div :class="{ 'has-error': errorHas('type') }">
          <label class="font-medium">Project type</label>
          <div class="relative mt-2">
            <base-select
              v-if="types.length"
              v-model="project.type"
              :model-value="project.type"
              class="mb-8 w-xs"
              field="value"
              :options="types"
            />
          </div>
          <span
            v-if="errorHas('type')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("type") }}
          </span>
        </div>
      </div>
      <div class="intro-y box p-2 md:p-5">
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('budget') }"
        >
          <label class="font-medium">Budget</label>
          <div class="relative mt-2">
            <input
              v-model="project.budget"
              type="text"
              class="input pr-12 w-full border col-span-4"
              placeholder="Budget"
            >
            <div
              class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
            >
              $
            </div>
          </div>
          <span
            v-if="errorHas('budget')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("budget") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('estimated_amount') }"
        >
          <label class="font-medium">Estimated amount</label>
          <div class="relative mt-2">
            <input
              v-model="project.estimated_amount"
              type="text"
              class="input pr-12 w-full border col-span-4"
              placeholder="Estimated amount"
            >
            <div
              class="absolute top-0 right-0 rounded-r w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
            >
              $
            </div>
          </div>
          <span
            v-if="errorHas('estimated_amount')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("estimated_amount") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('started_at') }"
        >
          <label class="font-medium">Start date</label>
          <div class="relative mt-2">
            <Calendar
              v-model="project.started_at"
              class="w-full border"
              date-format="yy-mm-dd"
              :show-time="true"
              hour-format="24"
              :month-navigator="true"
              :year-navigator="true"
              year-range="2020:2030"
              placeholder="Start date"
            />
          </div>
          <span
            v-if="errorHas('started_at')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("started_at") }}
          </span>
        </div>
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('ended_at') }"
        >
          <label class="font-medium">End date</label>
          <div class="relative mt-2">
            <Calendar
              v-model="project.ended_at"
              class="w-full border"
              date-format="yy-mm-dd"
              :show-time="true"
              hour-format="24"
              :month-navigator="true"
              :year-navigator="true"
              year-range="2020:2030"
              placeholder="End date"
            />
          </div>
          <span
            v-if="errorHas('estimated_amount')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("estimated_amount") }}
          </span>
        </div>
      </div>
      <div class="intro-y box p-2 md:p-5">
        <div
          class="mt-3"
          :class="{ 'has-error': errorHas('budget') }"
        >
          <label class="font-medium">Attachments</label>
          <FileUpload
            ref="attachmentUploader"
            :custom-upload="true"
            :multiple="true"
            @uploader="uploadAttachments"
          >
            <template #empty>
              <p class="mt-2 p-5 border-2 border-dashed text-center rounded">
                Drag and drop files to here to upload.
              </p>
            </template>
          </FileUpload>
          <span
            v-if="errorHas('attachments', 'attachments')"
            class="pristine-error text-theme-6 mt-2"
          >
            {{ errorGet("attachments", "attachments") }}
          </span>
          <div class="border-2 rounded mt-2">
            <div
              v-for="attachment of project.attachments"
              :key="attachment.id"
              class="group w-full h-full flex items-center justify-between border-b-2"
            >
              <div class="flex items-center space-x-2">
                <div class="w-6 h-6 flex items-center">
                  <base-icon
                    :name="'file/' + files.getIcon(attachment.file)"
                    size="sm"
                  />
                </div>
                <div>{{ attachment.name }}</div>
              </div>
              <div class="mr-2 flex items-baseline h-full justify-end">
                <a
                  class="opacity-0 group-hover:opacity-100 mr-5 hover:text-red-500 cursor-pointer"
                  @click.prevent="attachmentDelete(attachment.id)"
                >
                  <i class="ri-delete-bin-fill" />
                </a>
                <a
                  :href="attachment.file"
                  class="hover:text-blue-500"
                  target="_blank"
                  download
                >
                  <i class="ri-file-download-fill" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
